@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/photoswipe/src/css/default-skin/default-skin";
@import "node_modules/photoswipe/src/css/main";
@import "node_modules/rrssb/scss/rrssb";
@import "node_modules/pygments-rouge-css/default";

$myblue: #396b9a;
$mygreen: #589068;

$spacer: 1.5rem;

$headings-color: $myblue;

$body-color: #101010;
$link-color: $myblue;

$btn-primary-color: #fff;
$btn-primary-bg: $myblue;

$navbar-dark-color: #c1c1c1;
$navbar-dark-active-color: #eee;
$navbar-dark-hover-color: #eee;

@import "node_modules/bootstrap/scss/bootstrap";

hr {
    margin: 1.7rem 0;
    border-color: $myblue;
    border-style: dashed;
}

html {

    @include media-breakpoint-only(xl) {
        font-size: 1.2rem;
    }
    @include media-breakpoint-only(lg) {
        font-size: 1.1rem;
    }
    @include media-breakpoint-only(md) {
        font-size: 1.0rem;
    }
    @include media-breakpoint-only(sm) {
        font-size: 1.0rem;
    }
    @include media-breakpoint-only(xs) {
        font-size: 1.0rem;
    }
}

ul {
    list-style-type: square;
}

.embed-responsive {
    margin-bottom: ($spacer / 2);
}

blockquote {
    padding-left: 1rem;
    border-left: 6px solid $myblue;
}

// ---------------------------- Navbar ----------------------------
.navbar {
    margin-bottom: 1.7em;

    padding-right: 0 !important;
    padding-left: 0 !important;

    .container {
        display: block !important;
        padding-left: 15px;
        padding-right: 15px
    }

    li {
        margin-right: 1.9rem;
        margin-top: .7rem;
        margin-bottom: .7rem;

        a {
            padding-left: 0 !important;
            line-height: 1.5rem;
        }

        &.active {
            a {
                border-bottom: 5px solid $mygreen;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 0 !important;

        li {
            a {
                padding-top: 5px;
                padding-bottom: 0;
            }
        }

    }
}

// --------------------------- Top Link ----------------------------
#top-link-block {

    position: fixed;
    bottom: 10px;
    left: 10px;

    font-size: 2em;
    line-height: 2.2rem;
    padding: .3rem .5rem;

    background-color: #aaa;
}


// ----------------------------- Header ----------------------------
header#header {
    background-color: #ddd;
    img#header-logo {

        margin: 4em 0;
        width: 100%;
        display: block;

        @include media-breakpoint-down(md) {
            margin: 2.5em 0;
        }
    }
}

// --------------------------- Images ----------------------------
img.lightbox {
    cursor: zoom-in;
}

.images {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;

    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: .9rem;

    img {
        display: block;

        padding: 5px;
        margin-left: auto;
        margin-right: auto;

        &.fig-100 {
            width: 100%;
        }

        &.fig-50 {
            width: 50%;
        }

        &.fig-33 {
            width: 33.33%;
        }
    }
}

// -------------------------------- Post  ------------------------------
.panel {
    padding: .8em;
    border-left: 4px solid $mygreen;
    box-shadow: None;

    .fa {
        color: $mygreen;
    }

    #post_tags {
        float: right;
        margin-left: 1em;
    }

    margin-bottom: ($spacer/ 2);
}

// ----------------------------- Pagination ---------------------------
div.pagination {
    margin-top: 3em;
    width: 100%;
}

// ----------------------------- Share Buttons ---------------------------
.rrssb-buttons {
    margin: 3rem auto 0;
    max-width: 500px;
}

// ----------------------------- Markdown ------------------------------
figure.highlight {
    width: 100%;
    margin: 1em 0;
    table {
        table-layout: fixed;
        width: 100%;
    }
    pre {
        word-wrap: normal;
        margin: 0;
    }
}

// -------------------------- IPython Notebooks -------------------------
a.anchor-link {
    display: none;
    margin-left: .2em;
    border: none;
	  color: rgba($body-color, .1);
	  transition: all .2s ease-in-out;
	  &:hover {
		    color: rgba($mygreen, 1);
	  }
}

.output_png {
    margin-top: 1rem;
    text-align: center;
    img {
        max-width: 100%;
    }
}

.input_prompt {
    font-size: .85em;
    color: #777;
    margin-bottom: .3em;
}

// ----------------------------- Front Page ----------------------------
#front-image {
    @extend .rounded;

    max-width: 50%;
    margin-bottom: .2rem;

    @include media-breakpoint-up(sm) {
        margin-right: 1rem;
        margin-left: 0rem;
        float: left;
    }

    @include media-breakpoint-only(xs) {
        margin-right: 0rem;
        margin-left: 1rem;
        float: right;
    }
}

ul#contact {

    .fa {
        width: 1.5em;
    }
}

// ----------------------------- Publications ----------------------------
#publications {

    // ----- copyright notice -----
    .x-small {
        font-size: 0.75em;
    }

    h2.x-small{
        font-size: 1rem;
    }

    p.x-small {
        margin-bottom: .4rem;
    }

    // bibtex code
    .bibtex {
        font-size: .9rem;
        line-height: 130%;
    }
}


ul.pub-list,
ol.pub-list {
    li {
        margin-bottom: .6rem;
        /* line-height: 1; */

        img {
            vertical-align: baseline;
            margin-bottom: -1px;
        }
    }
}

// ----------------------------- Projects ----------------------------
img.project-img {
    @extend .img-thumbnail;

    float: right;
    max-width: 50%;
    width: 300px;
    margin-left: 1.5em;
}

// ------------------------------ Archives ----------------------------
#resume-table {
    td, th {
        padding: .25rem;
    }
}

// ------------------------------ Archives ----------------------------
#archive {
    table {
        width: 100%;
        max-width: 100%;
    }

    td {
        vertical-align: middle;

        &:first-of-type {
            text-align: right;
        }
    }


    .archive-timestamp {
        font-size: .75em;
        color: darken($body-color, 20);
    }
}

// ------------------------------- Footer ------------------------------
footer {
    background-color: #2c3b4a;
    text-align: center;
    padding-top: 4em;
    padding-bottom: 4em;
    margin-top: 3rem;
    color: rgba(255, 255, 255, .8);

    a {
        color: rgba(255, 255, 255, .8);
        &:hover {
            color: rgba(255, 255, 255, .8);
        }
    }
}

#socialmedia {
    cursor: default;
    padding-left: 0;
    font-size: 1.45em;
    list-style: none;
    margin: 1em 0 0em;
    li {
        display: inline-block;
        line-height: 2;
        margin: 0 .5em;
        a {
            border-bottom: none;
            color: rgba(255, 255, 255, .8);
            transition: color 0.2s ease-in-out, color 0.2s ease-in-out;
            &:hover {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
            }
            &:before {
                font-family: FontAwesome;
                font-weight: 400;
            }
            span {
                display: none;
            }
        }
    }
}
